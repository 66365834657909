<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="专辑名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="作者UCID">
        <el-input v-model="form.ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.nickname"></el-input>
      </el-form-item>
      <el-form-item label="专辑状态">
        <el-select v-model="form.status">
          <el-option label="全部" value="0"> </el-option>
          <el-option label="未启用" value="1"> </el-option>
          <el-option label="已启用" value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe @sort-change="handleSortChange">
        <el-table-column label="专辑名称" prop="album_name"></el-table-column>
        <el-table-column label="专辑主图" prop="cover"></el-table-column>
        <el-table-column label="作者信息" prop="info"></el-table-column>
        <el-table-column label="创建时间" prop="time" sortable="custom"></el-table-column>

        <el-table-column label="作品总数" prop="count"></el-table-column>
        <el-table-column label="恒境作品数" prop="forever_realm_count"></el-table-column>
        <el-table-column label="小店作品数" prop="shop_count"></el-table-column>
        <el-table-column label="未上架作品数" prop="off_shelf_count"></el-table-column>

        <el-table-column label="标签数" prop="album_count"></el-table-column>
        <el-table-column label="专辑状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_shop_open" type="success">已启用</el-tag>
            <el-tag v-else type="info">未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收藏数量" prop="like_count" sortable="custom"></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handlePreviewAlbum(scope.row)">
              预览专辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const MOCK = [
  {
    info: "152818236151812（子辰",
    time: "2022-4-16 10:33:26",
    name: "艺术学院小学生",
    count: 6,
    forever_realm_count: 2,
    shop_count: 2,
    off_shelf_count: 2,
    album_count: 2,
    is_shop_open: true,
    like_count: 100,
  },
  {
    info: "152818236151812（子辰",
    time: "2022-4-16 10:33:26",
    name: "艺术学院小学生",
    count: 6,
    forever_realm_count: 2,
    shop_count: 2,
    off_shelf_count: 2,
    album_count: 2,
    is_shop_open: false,
    like_count: 100,
  },
];
export default {
  data() {
    return {
      form: {
        status: "0",
      },
      list: [...MOCK],
      total: 0,
      pageSize: 20,
      page: 1,
      sortBy: "",
      sortOrder: "",
    };
  },
  computed: {
    params() {
      // const { min_nft_num, max_nft_num, nft_sale_start, nft_sale_end } = this.form;

      return {
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy || undefined,
        sort_order: this.sortOrder || undefined,
      };
    },
  },
  methods: {
    async fetchList() {},
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {
        is_shop_open: "0",
      };
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    handlePreviewAlbum() {},
    handleShopToggle(data) {
      const message = data.is_shop_open
        ? "关闭小店后，小店上架的作品将无法展示"
        : "开启小店，艺术家的个人中心将升级为小店";
      const title = data.is_shop_open ? "是否关闭小店？" : "是否开启小店？";

      this.$confirm(message, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleCrateMax(data) {
      this.$prompt("请输入铸造上限", "铸造上限修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.create_max,
        inputValidator: function(val) {
          return +val >= 100 && +val <= 1000;
        },
        inputErrorMessage: "铸造上限需：>=100 且 <=1000",
      }).then(({ value }) => {
        this.$message({
          type: "success",
          message: "修改为: " + value,
        });
      });
    },
  },
};
</script>

<style lang="less"></style>
